'use client'

import { createContext, FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { getCookie, setCookie } from 'cookies-next'

export type ContrastContextType = {
  contrast: boolean
   
  setContrast: (boolean) => void
}

export const ContrastContext = createContext<ContrastContextType>({
  contrast: false,
  setContrast: () => {},
})

const ContrastProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [contrast, setContrast] = useState<boolean>(false)
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const isContrast = getCookie('contrast') === 'true';
    setContrast(isContrast ? true : false)
    setInitialized(true);
  }, [])

  useEffect(() => {
    if (initialized) {
      setCookie('contrast', contrast ? 'true': 'false')
    }
    if (contrast) {
      document.body.classList.add('contrast')
    } else {
      document.body.classList.remove('contrast')
    }
  }, [contrast, initialized])

  return (
    <ContrastContext.Provider value={{ contrast, setContrast }}>
      {children}
    </ContrastContext.Provider>
  )
}

export default ContrastProvider
